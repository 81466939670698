import type { NotUndefined } from 'object-hash'
import { MD5 } from 'object-hash'

export function fastHash(input: NotUndefined | undefined | null) {
  if (input === undefined || input === null) {
    return 'hashed_null_or_undefined'
  }

  return MD5(input)
}
