import type { RouterOutputs } from '@api/trpc'
import type { VirtualItem } from '@tanstack/react-virtual'
import type { FC } from 'react'
import React, { useCallback } from 'react'
import { api } from '@api/api'
import { VirtualFeed } from '@components/ui/VirtualFeed'
import type { Post } from './HomePosts'
import { CommentMessage } from './CommentMessage'

export type ActivityItem =
  RouterOutputs['backend']['posts']['getPostComments']['data'][0]
export type PostComment = Post['activities'][0]
interface CommentsListProps {
  postId?: string
  preloadedComments?: Post['activities']
  isPostsLoading: boolean
  onCommentLikePressed?: (comment: PostComment) => void
  scrollHeight: number
}

export const CommentsList: FC<CommentsListProps> = ({
  postId,
  onCommentLikePressed,
  scrollHeight,
}) => {
  const getCommentsHook = api.posts.useGetPostComments({
    postId,
  })

  const renderMessage = useCallback(
    ({ item }: { item: PostComment; virtualItem: VirtualItem }) => (
      <CommentMessage
        comment={item}
        onCommentLikePressed={onCommentLikePressed}
      />
    ),
    [],
  )
  return (
    <VirtualFeed
      height={scrollHeight}
      estimatedItemHeight={80}
      infiniteQuery={getCommentsHook}
      renderItem={renderMessage}
      useSwipe={false}
    />
  )
}
