import React, { useCallback, useState } from 'react'
import { api } from '@api/api'
import { backendHook } from '@api/trpc'
import { Button } from '@components/ui/buttons/Button2'
import { Functions } from '@goatlab/js-utils'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@goatlab/react-ui'
import {
  useAccountStore,
  useCommentActivitiesStore,
  useHomeFeedStore,
  useNewPostStore,
} from '@sodium/shared-frontend-schemas'
import { env } from '@src/_env/env'
import { useIs } from '@src/hooks/useIs'
import { sodiumConfig } from '@src/sodiumConfig'
import copy from 'copy-to-clipboard'
import { MessageSquareMore, Pencil, Repeat2, Send, Share } from 'lucide-react'
import type { PostComment } from './CommentsList'
import type { Post } from './HomePosts'
import { AccountsLikedPostsDialog } from './AccountsLikedPostsDialog'
import { FooterActionButton } from './FooterActionButton'
import { PostCommentsDialog } from './PostCommentsDialog'
import { RepostDialog } from './RepostDialog'

interface Override {
  onIconPressed: (post: Post, originalAction: () => void) => void
  onTextPressed: (post: Post, originalAction: () => void) => void
}

// Current use case only allows for comments to be overridden but this can be extended to other actions
interface Overrides {
  // likes?: Partial<Override>
  comments?: Partial<Override>
  // repost?: Partial<Override>
  // share?: Partial<Override>
}

interface PostActionButtonsProps {
  post: Post
  isPreview?: boolean
  overrides?: Overrides
}

export const PostActionButtons = ({
  post,
  isPreview,
  overrides,
}: PostActionButtonsProps) => {
  const { isMobile } = useIs()
  const { toast } = useToast()
  const getShareLinkHook =
    backendHook.backend.links.createSharePostLink.useMutation()
  const { setRepostId } = useNewPostStore()
  const postId = post.originalPost?.id ?? post.id
  const [isRepostDialogOpen, setIsRepostDialogOpen] = useState(false)
  const [isShowLikedModal, setIsShowLikedModal] = useState(false)

  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTypedTranslation()
  const { setSelectedPost, visibleAccountId, selectedPost } = useHomeFeedStore()
  const { selectedAccount } = useAccountStore()
  const { reset } = useCommentActivitiesStore()

  const { mutateAsync: createPost } =
    backendHook.backend.posts.createPost.useMutation({
      onSuccess: () => {
        toast({
          title: `${t('text-repost-success', { ns: 'common' })}`,
        })
      },
      onError: () => {
        toast({
          title: `${t('text-repost-failed', { ns: 'common' })}`,
          variant: 'destructive',
        })
      },
      onMutate: () => {
        toast({
          title: `${t('text-repost-pending', { ns: 'common' })}`,
        })
      },
    })

  const handleRepost = async () => {
    const selectedPostId = selectedPost?.originalPost?.id ?? selectedPost?.id
    try {
      if (!selectedPostId) throw new Error('No post selected')

      await createPost({
        content: '',
        assets: [],
        repostId: selectedPostId,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleOpen = () => setOpen((value) => !value)

  const togglePostLike = api.posts.useTogglePostLike({
    account: {
      id: selectedAccount?.id || '',
      displayName: selectedAccount?.displayName || '',
      profilePicture: selectedAccount?.profilePicture || '',
      title: selectedAccount?.title || '',
    },
    visibleAccountId,
  })

  const toggleCommentLikeHook = api.posts.useToggleCommentLike({
    account: {
      id: selectedAccount?.id || '',
      displayName: selectedAccount?.displayName || '',
      profilePicture: selectedAccount?.profilePicture || '',
      title: selectedAccount?.title || '',
    },
    visibleAccountId,
  })

  const onCommentLikePressed = (comment: PostComment) => {
    if (!comment.postId || !selectedAccount?.id) {
      return
    }

    return toggleCommentLikeHook.mutateAsync({
      commentId: comment.id,
      selectedAccountId: selectedAccount?.id,
      postId: comment.postId,
    })
  }

  const onCommentPressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      if (post.id) {
        reset()
        setSelectedPost(post)
      }

      const action = overrides?.comments?.onIconPressed ?? handleOpen

      requestAnimationFrame(() => {
        action(post, handleOpen)
      })
    }, 40),
    [post, open],
  )

  const onCommentsCountPressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      if (post.id) {
        reset()
        setSelectedPost(post)
      }

      const action = overrides?.comments?.onTextPressed ?? handleOpen

      requestAnimationFrame(() => {
        action(post, handleOpen)
      })
    }, 40),
    [post, open],
  )

  const onSharePressed = useCallback(
    Functions.debounce(async () => {
      if (isPreview) {
        return
      }
      const content = `Hey! Check this in ${env.APP_NAME}`

      const postUrl = await getShareLinkHook.mutateAsync({ postId: post.id })

      const fallback = () => {
        const success = copy(postUrl)
        if (success) {
          toast({
            title: 'Post url copied to clipboard',
          })
        } else {
          toast({
            title: 'Failed to copy to clipboard',
            variant: 'destructive',
          })
        }
      }

      if (!isMobile) {
        return fallback()
      }

      if (!navigator.share) {
        return fallback()
      }

      try {
        await navigator.share({
          title: `Post`,
          text: content,
          url: postUrl,
        })
      } catch {}
    }, 40),
    [post],
  )

  const onLikePressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }
      setSelectedPost(post)
      void togglePostLike.mutateAsync({ postId: post.id })
    }, 40),
    [post],
  )

  const _onLikesCountPressed = useCallback(
    Functions.debounce(() => {
      if (isPreview) {
        return
      }

      setSelectedPost(post)

      requestAnimationFrame(() => {
        setIsShowLikedModal(true)
      })
    }, 40),
    [post],
  )

  return (
    <div className="w-full px-6 pb-3">
      <div className="flex w-full flex-wrap items-center justify-between gap-2 pb-1 pt-2">
        <FooterActionButton
          text={`${sodiumConfig.keywords.likes}  (${post.reactionsCount || 0})`}
          icon={sodiumConfig.icons.likePressed}
          iconActive={sodiumConfig.icons.likeDefault}
          active={post.liked}
          onIconPressed={onLikePressed}
          onTextPressed={_onLikesCountPressed}
        />

        <FooterActionButton
          text={`${t('comments', { ns: 'common' })}  (${post.commentsCount || 0})`}
          icon={<MessageSquareMore className="h-6 w-6 text-gray-500" />}
          iconActive={<MessageSquareMore className="h-6 w-6 text-gray-500" />}
          onIconPressed={onCommentPressed}
          onTextPressed={onCommentsCountPressed}
        />

        <Popover>
          <PopoverTrigger asChild>
            <div>
              <FooterActionButton
                text={t('text-repost', { ns: 'common' })}
                icon={<Repeat2 className="h-6 w-6 text-gray-500" />}
                iconActive={<Repeat2 className="h-6 w-6 text-gray-500" />}
              />
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-80 p-0" align="end">
            <div className="space-y-2 p-0">
              <Button
                variant="ghost"
                className="h-auto w-full justify-start py-3"
                onClick={() => {
                  setIsRepostDialogOpen(true)
                  setRepostId(postId)
                  setSelectedPost(post)
                }}
                size="lg"
              >
                <Pencil className="mr-3 h-5 w-5" />
                <div className="flex flex-col items-start">
                  <span className="font-medium">Share with your thoughts</span>
                  <span className="text-sm text-muted-foreground">
                    Create a new post
                  </span>
                </div>
              </Button>
              <Button
                variant="ghost"
                onClick={handleRepost}
                className="h-auto w-full justify-start py-3"
                size="lg"
              >
                <Share className="mr-3 h-5 w-5" />
                <div className="flex flex-col items-start">
                  <span className="font-medium">Share now</span>
                  <span className="text-sm text-muted-foreground">
                    Share instantly
                  </span>
                </div>
              </Button>
            </div>
          </PopoverContent>
        </Popover>

        <FooterActionButton
          text={`${t('text-share', { ns: 'common' })}  (${post.sharesCount || 0})`}
          icon={<Send className="h-6 w-6 text-gray-500" />}
          iconActive={<Send className="h-6 w-6 text-gray-500" />}
          onIconPressed={onSharePressed}
          onTextPressed={onSharePressed}
        />
      </div>

      <RepostDialog
        open={isRepostDialogOpen}
        onOpenChange={setIsRepostDialogOpen}
      />

      <PostCommentsDialog
        isDialogOpen={open}
        setIsDialogOpen={setOpen}
        onCommentLikePressed={onCommentLikePressed}
      />

      <AccountsLikedPostsDialog
        isDialogOpen={isShowLikedModal}
        setIsDialogOpen={setIsShowLikedModal}
      />
    </div>
  )
}
