import type { RouterOutputs } from '@api/trpc'
import type { FC } from 'react'
import React, { useState } from 'react'
import { DrawerDialog } from '@components/ui/dialog/DrawerDialog'
import { useHomeFeedStore } from '@sodium/shared-frontend-schemas'
import type { PostComment } from './CommentsList'
import { CommentInput } from './CommentInput'
import { CommentsList } from './CommentsList'

export type ActivityItem =
  RouterOutputs['backend']['posts']['getPostComments']['data'][0]

interface AccountsLikedPostsDialogProps {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
  onCommentLikePressed?: (comment: PostComment) => void
}

const snapPoints = [0.6, 1]
export const PostCommentsDialog: FC<AccountsLikedPostsDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  onCommentLikePressed,
}) => {
  const { selectedPost } = useHomeFeedStore()
  const [scrollHeight, setScrollHeight] = useState<number>(0)

  const postId = selectedPost?.id

  return (
    <DrawerDialog
      open={isDialogOpen}
      onClose={() => {
        setIsDialogOpen(false)
      }}
      title={'Comments'}
      snapPoints={snapPoints}
      onVisibleScrollHeightChange={setScrollHeight}
      footer={<CommentInput postId={postId} />}
    >
      <CommentsList
        scrollHeight={scrollHeight - 60}
        isPostsLoading={false}
        postId={postId}
        onCommentLikePressed={onCommentLikePressed}
      />
    </DrawerDialog>
  )
}
