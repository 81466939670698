import type { RouterOutputs } from '@api/trpc'
import type { VirtualItem } from '@tanstack/react-virtual'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { api } from '@api/api'
import { DrawerDialog } from '@components/ui/dialog/DrawerDialog'
import { VirtualFeed } from '@components/ui/VirtualFeed'
import { useHomeFeedStore } from '@sodium/shared-frontend-schemas'
import { sodiumConfig } from '@src/sodiumConfig'
import { AccountSearchResult } from './AccountSearchResult'

export type ActivityItem =
  RouterOutputs['backend']['posts']['getPostComments']['data'][0]

interface AccountsLikedPostsDialogProps {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
}

const snapPoints = [0.6, 1]
export const AccountsLikedPostsDialog: FC<AccountsLikedPostsDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const { selectedPost } = useHomeFeedStore()
  const postId = selectedPost?.id
  const getAccountsLikedPostHook = api.posts.useGetAccountsThatLikedPost({
    postId,
  })
  const [scrollHeight, setScrollHeight] = useState<number>(0)

  const renderAccount = useCallback(
    ({
      item,
      virtualItem,
    }: {
      item: ActivityItem['account']
      virtualItem: VirtualItem
    }) => {
      return (
        <div key={virtualItem.key}>
          <AccountSearchResult
            account={item}
            onAvatarPressed={() => {
              setIsDialogOpen(false)
            }}
            accessoryRight={
              <div className="flex">{sodiumConfig.icons.likePressed}</div>
            }
          />
        </div>
      )
    },
    [setIsDialogOpen],
  )

  return (
    <DrawerDialog
      open={isDialogOpen}
      onClose={() => {
        setIsDialogOpen(false)
      }}
      title={sodiumConfig.keywords.likes}
      snapPoints={snapPoints}
      onVisibleScrollHeightChange={setScrollHeight}
    >
      <VirtualFeed
        scrollHeight={scrollHeight}
        estimatedItemHeight={60}
        infiniteQuery={getAccountsLikedPostHook}
        renderItem={renderAccount}
      />
    </DrawerDialog>
  )
}
