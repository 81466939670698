import type { FC, ReactElement } from 'react'
import React, { memo, useEffect, useState } from 'react'
import { motion } from 'framer-motion'

interface FooterButtonProps {
  text?: string
  icon: ReactElement
  iconActive: ReactElement
  active?: boolean
  onIconPressed?: () => void
  onTextPressed?: () => void
}

export const FooterActionButton: FC<FooterButtonProps> = memo(
  ({
    text,
    icon,
    iconActive,
    active = false,
    onIconPressed,
    onTextPressed,
  }) => {
    const [liked, setLiked] = useState(active)

    useEffect(() => {
      setLiked(active)
    }, [active])

    return (
      <div className="flex flex-col items-center px-2 py-1">
        {/* Icon */}
        <motion.div
          className="relative cursor-pointer"
          onClick={() => {
            setLiked((prev) => !prev)
            if (onIconPressed) onIconPressed()
          }}
        >
          {/* Active icon (visible when liked is true) */}
          <motion.div
            className="absolute"
            whileHover={{ scale: 1.1 }}
            initial={{ scale: liked ? 0 : 1 }}
            animate={{ scale: liked ? 0 : 1 }}
          >
            {iconActive}
          </motion.div>

          {/* Default icon (visible when liked is false) */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            initial={{ scale: liked ? 1 : 0 }}
            animate={{ scale: liked ? 1 : 0 }}
          >
            {icon}
          </motion.div>
        </motion.div>

        {/* Text below the icon */}
        {text && (
          <motion.span
            whileHover={{ scale: 1.1 }}
            className="mt-1 cursor-pointer text-xs font-medium text-gray-500"
            onClick={onTextPressed}
          >
            {text}
          </motion.span>
        )}
      </div>
    )
  },
)
