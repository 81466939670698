import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import type { Range, VirtualItem } from '@tanstack/react-virtual'
import type { HTMLAttributes, ReactElement } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Functions } from '@goatlab/js-utils'
import { useFlashListStore } from '@sodium/shared-frontend-schemas'
import { cn } from '@src/utils/cn'
import { fastHash } from '@src/utils/fast-hash'
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual'
import { motion, useAnimation } from 'framer-motion'

export interface DataWithId {
  id: string | number
}

export interface PaginatedResult<T> {
  total: number
  perPage: number
  currentPage: number
  lastPage: number
  nextPage: number | null
  previousPage: number | null
  data: T[]
}

interface PaginatedBase {
  total: number
  perPage: number
  currentPage: number
  lastPage: number
  nextPage: number | null
  previousPage: number | null
  data: { id: string | number }[]
}

type ItemFromInfiniteQuery<T> = T extends { data?: { pages: (infer P)[] } }
  ? P extends { data: (infer D)[] }
    ? D extends DataWithId
      ? D
      : never
    : never
  : never

export interface VirtualFeedParams<
  T extends UseInfiniteQueryResult<{ pages: PaginatedBase[] }>,
> {
  infiniteQuery: T
  renderItem: (props: {
    virtualItem: VirtualItem
    item: ItemFromInfiniteQuery<T>
  }) => ReactElement
  horizontal?: boolean
  scrollToIndex?: number
  useSwipe?: boolean
  containerClassName?: HTMLAttributes<HTMLDivElement>['className']
  itemClassName?: HTMLAttributes<HTMLDivElement>['className']
  estimatedItemHeight: number
  estimatedItemWidth: number
  height: number
  width?: number | string
  overScan?: number
}

const MAX_VIEWABLE_ITEMS = 1

export function VirtualFeed<
  T extends UseInfiniteQueryResult<{ pages: PaginatedBase[] }, any>,
>({
  infiniteQuery,
  renderItem,
  horizontal = false,
  useSwipe = false,
  scrollToIndex,
  containerClassName,
  itemClassName,
  estimatedItemHeight,
  estimatedItemWidth,
  height,
  width,
  overScan,
}: VirtualFeedParams<T>) {
  const { status, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    infiniteQuery
  const {
    setFocusedItemId,
    setFocusedItem,
    setViewableItemIds,
    setViewableItems,
    setFocusedItemIndex,
    setViewableItemsIndex,
  } = useFlashListStore()
  const estimatedSize = useCallback(() => estimatedItemHeight, [])
  const estimatedWidth = useCallback(() => estimatedItemWidth, [])

  const flatData = useMemo(
    () => (data?.pages ?? []).flatMap((page) => page.data),
    [fastHash(data)],
  )
  const parentRef = React.useRef<HTMLDivElement>(null)
  const totalFetched = flatData.length

  const [activeItem, setActiveItem] = useState<string | null>(null)

  const setItemsInStore = (viewableItems: any) => {
    const focusedItem = viewableItems.slice(0, MAX_VIEWABLE_ITEMS)?.[0]
    const focusedItemId = focusedItem?.id
    const focusedItemIndex = focusedItem?.index

    const viewableItemIds = viewableItems.map((item: any) => item?.id)
    const viewableItemsIndex = viewableItems.map((item: any) => item?.index)

    setViewableItemIds(viewableItemIds)
    setViewableItemsIndex(viewableItemsIndex)
    setViewableItems(viewableItems)
    setFocusedItem(focusedItem)
    setFocusedItemId(focusedItemId)
    setFocusedItemIndex(focusedItemIndex)
  }

  const debouncedSetItems = Functions.debounce(setItemsInStore, 100)

  const observer = useRef<IntersectionObserver | null>(null)

  // IntersectionObserver to be able to figure out the active element
  useEffect(() => {
    const ratios: Record<string, number> = {}

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const getId = entry.target as any
          const id = getId.dataset.id
          ratios[id] = entry.intersectionRatio
        })

        let activePage = { id: '', ratio: 0 }
        Object.entries(ratios).forEach(([id, ratio]) => {
          if (ratio > activePage.ratio) {
            activePage = { id, ratio }
          }
        })

        if (activePage) {
          setActiveItem(activePage.id)
        }
      },
      {
        threshold: [0.1, 0.5, 1],
        root: null,
      },
    )

    return () => observer.current?.disconnect()
  }, [])

  useEffect(() => {
    if (activeItem) {
      const activeIndex = Number(activeItem)
      const item = flatData[activeIndex]
      debouncedSetItems([item])
    }
  }, [activeItem])

  const observeElement = useCallback((element: any) => {
    if (observer.current && element) {
      observer.current.observe(element)
    }
  }, [])

  const rangeExtractor = useCallback((range: Range) => {
    return defaultRangeExtractor(range)
  }, [])

  const virtualizer = useVirtualizer({
    getScrollElement: () => parentRef?.current || null,
    horizontal,
    count: hasNextPage ? totalFetched + 1 : totalFetched,
    estimateSize: horizontal ? estimatedWidth : estimatedSize,
    overscan: overScan || 5,
    rangeExtractor,
  })

  const controls = useAnimation()
  // Handle swipe gestures
  const handleSwipe = async (offsetY: number) => {
    const currentIndex = virtualizer.getVirtualItems()[0]?.index || 0

    if (offsetY > 50) {
      // Swipe down (next item)
      const nextIndex = Math.min(totalFetched - 1, currentIndex + 1)
      virtualizer.scrollToIndex(nextIndex, { align: 'center' })
    } else if (offsetY < -50) {
      // Swipe up (previous item)
      const prevIndex = Math.max(0, currentIndex - 1)
      virtualizer.scrollToIndex(prevIndex, { align: 'center' })
    }

    // Animate back to center if not swiped enough
    await controls.start({
      y: 0,
      transition: { type: 'spring', stiffness: 300 },
    })
  }

  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    if (
      lastItem.index >= totalFetched - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      void fetchNextPage()
    }
  }, [
    hasNextPage,
    fetchNextPage,
    totalFetched,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ])

  useEffect(() => {
    if (
      scrollToIndex !== undefined &&
      status === 'success' &&
      totalFetched > 0 &&
      scrollToIndex < totalFetched
    ) {
      virtualizer.scrollToIndex(scrollToIndex, { align: 'center' })
    }
  }, [scrollToIndex, virtualizer, status, flatData])

  return (
    <div>
      {status === 'pending' ? (
        <p>Loading...</p>
      ) : (
        <div
          ref={parentRef}
          style={{
            height,
            width: horizontal ? width : `100%`,
            minHeight: '200px',
            overflow: 'auto',
            flex: 1,
          }}
          className="scrollbar-hide pt-4"
        >
          <motion.div
            className={cn(containerClassName, 'scrollbar-hide')}
            style={{
              height: horizontal ? '100%' : `${virtualizer.getTotalSize()}px`,
              width: horizontal ? `${virtualizer.getTotalSize()}px` : '100%',
              position: 'relative',
            }}
            {...(useSwipe && {
              drag: horizontal ? 'x' : 'y',
              // dragConstraints: { top: -100, bottom: 100 },
              // dragElastic: 0.2,
              animate: controls,
              onDragEnd: horizontal
                ? (_, info) => handleSwipe(info.offset.x)
                : (_, info) => handleSwipe(info.offset.y),
            })}
          >
            {virtualizer.getVirtualItems().map((virtualItem) => {
              const item = flatData[
                virtualItem.index
              ] as ItemFromInfiniteQuery<T>

              if (!item) {
                return null
              }

              return (
                <div
                  key={item.id}
                  data-id={virtualItem.index}
                  ref={observeElement}
                  className={cn('w-full', itemClassName)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: horizontal ? `${virtualItem.size}px` : '100%',
                    height: horizontal ? '100%' : `${virtualItem.size}px`,
                    transform: horizontal
                      ? `translateX(${virtualItem.start}px)`
                      : `translateY(${virtualItem.start}px)`,
                  }}
                >
                  {renderItem({ item, virtualItem })}
                </div>
              )
            })}
          </motion.div>
        </div>
      )}
    </div>
  )
}
