import { backendHook } from '@api/trpc'
import { useAuth } from '@components/auth/store/auth.store'
import { Button } from '@components/ui/buttons/Button2'
import { RichTextArea } from '@components/ui/forms/richText/RichTextArea'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import {
  Card,
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  useToast,
} from '@goatlab/react-ui'
import { useZodFormHook } from '@goatlab/react-zod-form'
import { useHomeFeedStore } from '@sodium/shared-frontend-schemas'
import { z } from 'zod'
import { AccountSearchResult } from './AccountSearchResult'
import { HomePost } from './HomePost'

interface RepostDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

const repostSchema = z.object({
  content: z.string().min(1),
})

export const RepostDialog = ({ open, onOpenChange }: RepostDialogProps) => {
  const { selectedPost } = useHomeFeedStore()
  const { t } = useTypedTranslation()
  const { toast } = useToast()
  const { user } = useAuth()

  const repostFormHook = useZodFormHook({
    schema: repostSchema,
    defaultValues: {
      content: '',
    },
  })

  const { mutateAsync: createPost } =
    backendHook.backend.posts.createPost.useMutation({
      onSuccess: () => {
        toast({
          title: `${t('text-repost-success', { ns: 'common' })}`,
        })
      },
      onError: () => {
        toast({
          title: `${t('text-repost-failed', { ns: 'common' })}`,
          variant: 'destructive',
        })
      },
      onMutate: () => {
        toast({
          title: `${t('text-repost-pending', { ns: 'common' })}`,
        })
      },
    })

  const onSubmit = repostFormHook.handleSubmit(async (data) => {
    await createPost({
      content: data.content,
      assets: [],
      repostId: selectedPost?.id,
    })
    onOpenChange(false)
  })

  return (
    <Credenza open={open} onOpenChange={onOpenChange}>
      <CredenzaContent className="sm:max-w-[800px]">
        <CredenzaHeader>
          <CredenzaTitle>Repost</CredenzaTitle>
          {user?.account && <AccountSearchResult account={user.account} />}
          {selectedPost && (
            <CredenzaDescription>
              Repost the post from {selectedPost?.account.displayName}
            </CredenzaDescription>
          )}
        </CredenzaHeader>
        <CredenzaBody>
          <RichTextArea
            name={repostFormHook.path.content}
            formHook={repostFormHook}
            placeholder={`Share your thoughts on ${selectedPost?.account.displayName}'s post`}
            autoFocus={true}
            defaultValue={''}
            config={{
              image: false,
              video: false,
              document: false,
            }}
          />
          <Card className="my-2">
            <HomePost
              post={selectedPost as any}
              showPostModal={() => {}}
              isPreview
            />
          </Card>
        </CredenzaBody>
        <CredenzaFooter>
          <CredenzaClose asChild>
            <button>Close</button>
          </CredenzaClose>
          <Button onClick={onSubmit}>Repost </Button>
        </CredenzaFooter>
      </CredenzaContent>
    </Credenza>
  )
}
