import React, { useState } from 'react'
import { useRouter } from 'next/navigation'
import { useAuth } from '@components/auth/store/auth.store'
import { Button } from '@components/ui/buttons/Button2'
import { RichTextDisplay } from '@components/ui/forms/richText/RichTextDisplay'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { cn } from '@goatlab/react-ui'
import { useIs } from '@src/hooks/useIs'
import { motion } from 'framer-motion'
import type { Post } from './HomePosts'
import { AssetViewer } from '../post/assetViewer/AssetViewer'
import { PostAccount } from './PostAccount'
import { PostActionButtons } from './PostActionButtons'

interface HomePostProps {
  post: Post
  showPostModal: () => void
  isViewable?: boolean
  isPreview?: boolean
  isRepost?: boolean
}

const PostContent = ({
  post,
  isRepost,
  isPreview,
  isViewable,
  showPostModal,
}: HomePostProps) => {
  const router = useRouter()
  const hasAssets = post.assets.length > 0
  const { user } = useAuth()
  const [isShowingTranslation, setIsShowingTranslation] = useState(false)
  const { t } = useTypedTranslation()
  const toggleTranslations = () => setIsShowingTranslation((value) => !value)
  const translation = post.translations.at(0)?.content
  const { isMobile } = useIs()

  const shouldDisplayTranslate =
    post.language && !!translation
      ? post.language !== user?.account?.language
      : false

  const onPostClicked = () => {
    if (!isRepost) {
      return
    }
    const postId = post.originalPost?.id ?? post.id

    router.push(`/posts/${postId}`)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className={cn(
        'my-3 bg-white',
        isRepost && 'scale-95 rounded-md border border-primary',
        !isMobile && 'shadow-md',
      )}
    >
      <PostAccount
        post={post}
        isPreview={isPreview}
        isRepost={isRepost}
        showPostModal={showPostModal}
      />

      {hasAssets && (
        <AssetViewer
          assets={post?.assets}
          isViewable={isViewable}
          postId={post.id}
        />
      )}

      <div
        onClick={onPostClicked}
        className={cn(isRepost && 'cursor-pointer', 'px-4 py-2 text-sm')}
      >
        <RichTextDisplay content={post.content} />
      </div>

      {shouldDisplayTranslate && (
        <Button variant="ghost" onClick={toggleTranslations}>
          {isShowingTranslation
            ? t('hide-translations', { ns: 'common' })
            : t('show-translations', { ns: 'common' })}
        </Button>
      )}
      {!isPreview && !isRepost && (
        <PostActionButtons post={post} isPreview={isPreview} />
      )}
    </motion.div>
  )
}

export const HomePost: React.FC<HomePostProps> = ({
  post,
  showPostModal,
  isPreview,
  isViewable,
}) => {
  const { user } = useAuth()
  const [isShowingTranslation, setIsShowingTranslation] = useState(false)
  const { t } = useTypedTranslation()
  const toggleTranslations = () => setIsShowingTranslation((value) => !value)
  const translation = post.translations.at(0)?.content

  const shouldDisplayTranslate =
    post.language && !!translation
      ? post.language !== user?.account?.language
      : false
  const isRepost = !!post.originalPost && !!post.repostId
  return (
    <div className="mt-3 bg-white">
      {!isPreview && isRepost && (
        <div className="bg-white p-1">
          <PostAccount
            post={post}
            isPreview={isPreview}
            showPostModal={showPostModal}
          />
          <div className="px-4 py-2 text-sm">
            <RichTextDisplay content={post.content} />
          </div>
          {shouldDisplayTranslate && (
            <Button variant="ghost" onClick={toggleTranslations}>
              {isShowingTranslation
                ? t('hide-translations', { ns: 'common' })
                : t('show-translations', { ns: 'common' })}
            </Button>
          )}
        </div>
      )}
      <PostContent
        post={(post.originalPost as unknown as Post) ?? post}
        isRepost={isRepost}
        isPreview={isPreview}
        isViewable={isViewable}
        showPostModal={showPostModal}
      />
      {!isPreview && isRepost && (
        <PostActionButtons post={post} isPreview={isPreview} />
      )}
    </div>
  )
}
