import type { FC } from 'react'
import React, { memo, useState } from 'react'
import { api } from '@api/api'
import { useAuth } from '@components/auth/store/auth.store'
import { Button } from '@components/ui/buttons/Button2'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@components/ui/dropdown-menu'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Dialog, DialogTrigger, toastSonner } from '@goatlab/react-ui'
import { useNewPostStore } from '@sodium/shared-frontend-schemas'
import { useIs } from '@src/hooks/useIs'
import { sodiumConfig } from '@src/sodiumConfig'
import { fromNowWithDateFns } from '@src/utils/date/fromNowWithDateFns'
import { useGoToConversation } from '@src/utils/router/useGoToConversation'
import {
  MessageCircleMore,
  MessageSquareWarningIcon,
  MoreVertical,
} from 'lucide-react'
import type { Post } from './HomePosts'
import { ReportOption } from '../post/ReportOption'
import { UnFollowOption } from '../post/UnFollowOptions'
import { AccountSearchResult } from './AccountSearchResult'

interface PostAccountProps {
  post: Post
  showPostModal: () => void
  isPreview?: boolean
  isRepost?: boolean
}

export const PostAccount: FC<PostAccountProps> = memo(
  ({ post, showPostModal, isPreview, isRepost }) => {
    const { navigateToConversation } = useGoToConversation()
    const { isMobile } = useIs()
    const [isReportModal, setIsReportModal] = useState(false)
    const { user } = useAuth()
    const deletePostHook = api.posts.useDeletePost({
      visibleAccountId: post?.account.id,
      beforeMutate: async () => {},
      afterMutate: async () => {},
      onSuccess: () => {},
    })

    const { setPostId, setAssets, setContent, resetKey } = useNewPostStore()
    const isOwner = user?.account?.id === post?.account.id
    const { t } = useTypedTranslation()

    const handleDeletePost = () => {
      if (!post.id) {
        return
      }

      toastSonner.promise(deletePostHook.mutateAsync({ postId: post.id }), {
        loading: 'Deleting your post...',
        success: () => 'Post deleted',
        position: isMobile ? 'top-center' : 'top-right',
        error: (error) => {
          console.error('error??', error)
          return 'There was an error deleting your post'
        },
      })
    }

    const handleSendMessage = () => {
      navigateToConversation({
        conversationId: post?.account.id,
        account: {
          id: post?.account.id,
          name: post?.account.displayName,
          avatar: post?.account.profilePicture,
        },
      })
    }

    const handleEditPost = () => {
      if (!post.id) {
        return
      }

      setPostId(post.id)
      setContent(post.content)
      setAssets(post?.assets)
      resetKey()
      showPostModal()
    }

    return (
      <div className="flex pt-2">
        <AccountSearchResult
          account={post.account}
          bottomText={<>{fromNowWithDateFns(post.created)}</>}
        />

        {!isPreview && !isRepost && (
          <div className="items-center justify-center pr-2 pt-2">
            <Dialog open={isReportModal} onOpenChange={setIsReportModal}>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div>
                    <Button size="icon" variant="ghost" className="h-8 w-8">
                      <MoreVertical
                        style={{
                          width: isMobile ? 24 : 22,
                          height: isMobile ? 24 : 22,
                        }}
                      />
                      <span className="sr-only">More</span>
                    </Button>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {isOwner ? (
                    <div>
                      <DropdownMenuItem onClick={() => handleEditPost()}>
                        {t('edit-post', { ns: 'common' })}
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        className="text-red-500"
                        onClick={() => handleDeletePost()}
                      >
                        {t('delete-post', { ns: 'common' })}
                      </DropdownMenuItem>
                    </div>
                  ) : (
                    <>
                      <DropdownMenuItem onClick={handleSendMessage}>
                        <div className="flex flex-row items-center">
                          <MessageCircleMore
                            className="mr-2 h-5 w-5"
                            color={sodiumConfig.colors.iconColor}
                          />

                          {t('send-message', { ns: 'common' })}
                        </div>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <UnFollowOption account={post?.account} />
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DialogTrigger asChild>
                        <DropdownMenuItem className="text-red-500">
                          <MessageSquareWarningIcon
                            color="red"
                            className="mr-2 h-5 w-5"
                          />
                          {t('report', { ns: 'common' })}
                        </DropdownMenuItem>
                      </DialogTrigger>
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <ReportOption
                accountId={post?.account.id}
                postId={post?.id}
                onDialogClosed={() => setIsReportModal(false)}
              />
            </Dialog>
          </div>
        )}
      </div>
    )
  },
)
