import type { FC } from 'react'
import React from 'react'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { sodiumConfig } from '@src/sodiumConfig'
import { UserRoundXIcon } from 'lucide-react'

interface ReportModalType {
  account: {
    id: string
    displayName?: string | null
    profilePicture?: string | null
    slug?: string | null
  }
  onPressed?: () => void
}

export const UnFollowOption: FC<ReportModalType> = () => {
  const { t } = useTypedTranslation()
  return (
    <div className="flex flex-row items-center">
      <UserRoundXIcon
        className="mr-2 h-5 w-5"
        color={sodiumConfig.colors.iconColor}
      />
      {t('stop-following', { ns: 'common' })}
    </div>
  )
}
